<template>
  <el-dialog
    :visible="dialogVisible"
    :title="title"
    width="450px"
    append-to-body
    @open="open"
    @close="close"
  >
    <div>
      <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        label-position="right"
        label-width="110px"
      >
        <el-form-item label="选择用户端" prop="role">
          <el-select v-model="form.role" class="g-inp" placeholder="请选择">
            <el-option v-if="+userType === 1" label="货主" :value="1" />
            <el-option v-if="+userType === 2" label="承运" :value="2" />
            <el-option v-if="+userType === 3" label="司机" :value="3" />
            <!-- <el-option
              v-for="item in allUserType"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            /> -->
          </el-select>
        </el-form-item>
        <el-form-item label="输入昵称" prop="userNickName">
          <el-input v-model="form.userNickName" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="手机号码" prop="userPhone">
          <el-input v-model.trim="form.userPhone" placeholder="请输入" maxlength="11" clearable />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">注册</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { validateTel } from '@/utils/validateRule';
import { goodsUserAdd, transUserAdd } from '@/api/userManageMent';
export default {
  props: {
    userType: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title: {
      type: String,
      default() {
        return '新增用户';
      },
    },
  },
  data() {
    return {
      form: {
        role: +this.userType,
        userNickName: '',
        userPhone: '',
      },
      rules: {
        role: [{ required: true, message: '请选择用户端', trigger: 'change' }],
        userNickName: [{ required: true, message: '请输入昵称', trigger: 'change' }],
        userPhone: [{ required: true, validator: validateTel }],
      },
    };
  },
  methods: {
    open() {
      // drawerTypeDesc.value = props.drawerType
    },
    close() {
      this.form = {
        role: +this.userType,
        userNickName: '',
        userPhone: '',
      };
      this.$refs.formRef.resetFields();
      this.$emit('update:dialogVisible', false);
    },
    submit() {
      this.$refs.formRef.validate(async(valid) => {
        if (valid) {
          try {
            const params = {
              userNickName: this.form.userNickName,
              userPhone: this.form.userPhone,
            };
            const apiMap = {
              1: goodsUserAdd,
              2: transUserAdd,
              // 3: transUserList(params)
            };
            const res = await apiMap[this.userType](params);
            if (res) {
              this.$baseMessage('新增成功', 'success');
              this.$emit('addUserDialogSubmit');
              this.close();
            } else {
              this.$baseMessage('新增失败', 'error');
            }
          } catch (error) {
            console.error('失败:', error);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
