<template>
  <div class="tab-list-container g-search-form-table">
    <search-form ref="form" :model="form" :count="8" label-width="auto" :collapse="false" @search="searchHandle" @reset="reset">
      <search-item label="" prop="userName">
        <el-input v-model.trim="form.userName" clearable placeholder="姓名" />
      </search-item>
      <search-item label="" prop="userPhone">
        <el-input v-model.trim="form.userPhone" clearable placeholder="手机号码" />
      </search-item>
      <search-item label="" prop="userPassFlag">
        <el-select v-model="form.userPassFlag" placeholder="实名认证状态" clearable>
          <!-- <el-option label="全部" :value="null" /> -->
          <el-option label="已实名" :value="1" />
          <el-option label="未实名" :value="0" />
        </el-select>
      </search-item>
      <search-item label="" prop="titleType">
        <el-select v-model="form.titleType" placeholder="资质类型" clearable>
          <!-- <el-option label="全部" :value="null" /> -->
          <el-option label="承运商企业" :value="11" />
          <el-option label="信息公司" :value="12" />
          <el-option label="个体运力" :value="13" />
        </el-select>
      </search-item>
      <search-item label="" prop="ext2">
        <el-input v-model.trim="form.ext2" clearable placeholder="创建人" />
      </search-item>
      <search-item label="" prop="ext1">
        <el-select v-model="form.ext1" placeholder="来源" clearable>
          <el-option label="全部" :value="null" />
          <el-option label="自主注册" :value="1" />
          <el-option label="平台注册" :value="0" />
        </el-select>
      </search-item>
      <search-item label="" prop="userId">
        <el-input v-model.trim="form.userId" clearable placeholder="用户ID" />
      </search-item>
      <search-item label="" prop="createTime">
        <el-date-picker
          v-model="createTime"
          type="datetimerange"
          start-placeholder="注册开始时间"
          end-placeholder="注册结束时间"
          value-format="timestamp"
          @change="timeChange"
        />
      </search-item>
    </search-form>
    <Table
      :loading="loading"
      :table-cols="tableCols"
      :table-data="tableData"
      :table-btns="tableBtns"
      :pagination="pagination"
      @sizeChange="sizeChange"
      @pageChange="pageChange"
    >
      <template v-slot="{ col, row }">
        <div v-if="col.prop === 'ext3'">
          <div class="ellipsis">
            <span v-for="(item, index) in row.customerUserRelVOList" :key="index" class="mr10">
              {{ item.name || '-' }}
            </span>
          </div>
        </div>
      </template>
    </Table>
    <AddUserDialog
      :dialog-visible.sync="dialogVisible"
      :user-type="2"
      @addUserDialogSubmit="getList()"
    />
  </div>
</template>

<script>
import AddUserDialog from '../dialog/addUserDialog';
// import CarrierDialog from './dialog/carrierDialog';
import { transUserList } from '@/api/userManageMent';
export default {
  components: { AddUserDialog },
  data() {
    return {
      loading: false,
      createTime: [],
      form: {},
      tableBtns: [
        {
          label: '新增用户',
          icon: 'add',
          evt: () => {
            this.openDialog();
          },
        },
      ],
      tableCols: [
        { label: '用户ID', prop: 'userId' },
        { label: '昵称', prop: 'userNickName' },
        { label: '姓名', prop: 'userName' },
        { label: '手机号', prop: 'userPhone' },
        { label: '注册时间', prop: 'createTime', type: 'filterTime', width: '160' },
        {
          label: '默认资质类型',
          prop: 'titleType',
          type: 'transition',
          rulesObj: this.$CONSTANT.qualificationTypeMap,
        },
        {
          label: '来源',
          prop: 'ext1',
          type: 'transition',
          rulesObj: { 1: '自主注册', 2: '平台注册' },
        },
        { label: '创建人', prop: 'ext2' },
        {
          label: '实名认证',
          prop: 'userPassFlag',
          type: 'transition',
          rulesObj: { 1: '已实名', 0: '未实名' },
          // 3: '审核中', 4: '认证失败'
        },
        { label: '关联客服', prop: 'ext3', type: 'customize' },
        {
          label: '认证业务资质',
          prop: 'passQualificationCount',
        },
        {
          label: '账号状态',
          prop: 'state',
          type: 'transition',
          rulesObj: { 2: '禁用', 1: '启用' },
        },
        {
          label: '操作',
          type: 'operation',
          fixed: 'right',
          btns: [
            {
              label: '详情',
              evt: (data) => {
                this.$router.push(`/user/personal/detail?id=${data.userId}&type=${2}`);
              },
            },
          ],
        },
      ],
      tableData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 30,
      },
      dialogVisible: false,
      // carrierDialogVisible: false,
      // currentData: {},
    };
  },
  // created() { this.getList(); },
  methods: {
    getList() {
      this.loading = true;
      const params = {
        isSearchRel: 1, // 是否只查客服绑定用户 1是
        ...this.form,
        size: this.pagination.size,
        current: this.pagination.page,
      };
      transUserList(params).then(res => {
        this.tableData = res?.records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    timeChange(e) {
      this.form.beginTime = e?.[0] || null;
      this.form.endTime = e?.[1] || null;
    },
    searchHandle() {
      this.pagination.page = 1;
      this.getList();
    },
    reset() {
      this.createTime = [];
      this.getList();
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    // 打开侧面抽屉 进行新增/编辑/查看操作
    openDialog() {
      this.dialogVisible = true;
    },
    // 打开关联承运商
    openCarrierDialog(data) {
      this.currentData = data || {};
      this.carrierDialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
