<template>
  <div class="page-container personal-wrap">
    <el-tabs v-model="tabPane">
      <el-tab-pane label="货主" name="1" />
      <el-tab-pane label="承运商" name="2" />
      <!-- <el-tab-pane label="司机" name="3" /> -->
    </el-tabs>
    <div class="task-nav-wrap">
      <Owner v-show="+tabPane === 1" ref="ownerRef" />
      <Carrier v-show="+tabPane === 2" ref="carrier" />
      <!-- <Driver v-show="+tabPane === 3" ref="driver" /> -->
    </div>
  </div>
</template>

<script>
import Owner from './components/owner/index';
import Carrier from './components/carrier/index';
// import Driver from './components/driver/index';

export default {
  name: 'GoodsorderSupplytask',
  components: { Owner, Carrier },
  data() {
    return {
      tabPane: '1',
    };
  },
  watch: {
    tabPane(val) {
      if (val === '1') {
        this.$refs.ownerRef.getList();
      } else if (val === '2') {
        this.$refs.carrier.getList();
      } else if (val === '3') {
        this.$refs.driver.getList();
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
